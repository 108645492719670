@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  background: #fff;
}

.PropertiesContainer {
  flex: 1 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20) 0 calc(rem(50) + rem(62.88)) 0;
}

.clarification {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 1.25rem;

  h2 {
    width: 95%;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #5c5b5b;
  }
}

.property {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: rem(30);

  &:last-of-type {
    padding-bottom: 0;
  }
}

.smallMargin {
  margin-top: 0.3rem;
  max-width: 90%;
}

.empty {
  height: 100%;
  display: flex;

  h2 {
    text-align: center;
    align-self: center;
    font-style: italic;
    font-weight: 400;
    font-size: 1.5rem;
    color: #c0c0c0;
  }
}
