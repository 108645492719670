@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f9;
}

.accountInformation {
  width: 100%;
  background-color: #f8f8f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 92%;
  background-color: #ffffff;
  padding: 2rem 1rem 1rem 1rem;
  margin-top: rem(6);
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  img {
    height: 10rem;
    width: 10rem;
    border: 3px solid #2898ff;
    border-radius: 50%;
    object-fit: cover;
  }

  h2,
  h3 {
    font-family: "Poppins";
    text-align: center;
    font-weight: 500;
  }

  h2 {
    font-size: 1rem;
    color: #1e456a;
  }

  h3 {
    font-size: rem(14);
    text-transform: capitalize;
    color: #ffffff;
    white-space: nowrap;
  }

  svg {
    margin-right: 0.5rem;
  }
}

.editButton {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  h2 {
    color: #2898ff;
    text-decoration: underline;
  }
}

.name {
  padding-top: rem(8);
  h2 {
    font-size: rem(20);
    text-transform: capitalize;
  }
}

.position {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: rem(8);
}

.email {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    text-transform: none;
  }
}

.phone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: rem(8);
  text-decoration: none;

  h2 {
    color: #2898ff;
  }
}

.platesContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.plate {
  background-color: rgba(40, 152, 255, 0.7);
  width: 50%;
  padding: rem(20);
  border-radius: rem(5);

  &:nth-child(2) {
    margin-left: 1rem;
  }
}
