.dropdown {
  position: absolute;
  top: 3.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #ffffff;
  border-radius: rem(10);
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.2);
}

.sortItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem;

  svg {
    margin-right: 0.5rem;
    min-height: unset;
    min-width: unset;
    height: 1rem;
    width: 1rem;
  }

  &_active {
    color: #2898ff;
  }
}
