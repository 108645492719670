@import "../../mixins/pxrem.scss";

.mapPolygonsPopupContainer {
  position: absolute;
  top: 0;
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 999;
}

.mapPolygonsPopup {
  width: fit-content;
  max-width: 80%;
  border-radius: rem(10);
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem 1rem;
  line-height: rem(35);
  color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
}
