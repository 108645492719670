.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0.8rem 0.2rem;

  input {
    margin-top: 0.225rem;
  }

  label {
    font-size: 0.875rem;
    font-weight: 400;
    padding-left: 0.7rem;
    font-style: normal;
    font-family: "Poppins";
    color: #222222;
  }
}
