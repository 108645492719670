@import "../../mixins/pxrem.scss";

.imageViewer {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  height: 100%;
  width: 100%;
  z-index: 10;

  h2,
  h3 {
    font-family: Roboto, sans-serif;
  }
}

.imageViewerNavBar {
  display: flex;
  justify-content: space-between;
  padding: rem(22) rem(15) rem(22) rem(15);

  h2 {
    font-size: 1rem;
    color: #fff;
    opacity: 0.75;
  }

  svg {
    width: 1rem;
    height: 1rem;
    display: block;
    opacity: 0.75;
    transition: opacity 0.2s;

    path {
      fill: #ffffff;
    }
  }
}

.imageViewerImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0;

  img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
}

.imageViewerImageNavigation {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;

  svg {
    cursor: pointer;
    display: block;
    height: 1rem;
    width: 1rem;
  }
}

.imageViewerWarning {
  padding: rem(12) rem(12) 1.5rem 0;
  width: 100%;

  h2,
  h3 {
    font-size: rem(13);
    color: hsla(0, 0%, 100%, 0.5);
    text-align: center;
    line-height: rem(20);
  }
}
