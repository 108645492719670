.container {
  height: 100%;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 1rem;
}

.scheduledNotification {
  display: flex;
  align-items: center;
  h3 {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    font-family: "Poppins";
    color: #222222;
    padding-bottom: 0.5rem;
    padding-right: 0.7rem;
  }
}

.buttonWrapper {
  padding: 1rem 1rem 1rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 0;
}
