.container {
  label,
  h2,
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #6b6f7b;
  }

  label {
    padding-left: 1rem;
  }

  @media (min-width: 768px) {
    max-width: 25rem;
  }
}

.saleTypeWrapper {
  padding: 2rem;
}

.offsideTypesWrapper {
  padding: 2rem;
  border-top: 2px solid rgba(211, 220, 225, 0.2);
}

.saleTypeItemOffside {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.saleTypeItem {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.8rem;

  svg {
    margin-left: 1rem;
  }
}

.radioWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.soldRangeSelector {
  padding-left: 1rem;

  h3 {
    margin-bottom: 1rem;
  }
}
