@import "../../../../mixins/pxrem.scss";
@import "../../../../mixins/mediaBreaks.scss";

.title {
  width: 100%;
  text-align: center;
  padding-bottom: rem(25);
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: rem(18);
    color: #333333;
  }
}
.paddingWrapper {
  padding: rem(20) rem(20) 0 rem(20);
}
.adressInputContainer {
  position: relative;
  padding-bottom: rem(25);
  input {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    font-size: rem(14);
    padding: rem(11) 0 rem(11) rem(13);
    transition: border 0.5s ease-in-out;
    &::placeholder {
      color: #c0c0c0;
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
    }
  }
  &_error {
    input {
      border: 1px solid red;
    }
  }
}
.searchResults {
  border-radius: 10px;
  max-height: 25vh;
  overflow-y: scroll;
  left: 0;
  z-index: 2;
  position: absolute;
  width: 100%;
  box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0 0 0 rem(23);
  list-style: none;
  background: #ffffff;
  li {
    padding-bottom: rem(20);
    &:first-of-type {
      padding-top: rem(20);
    }
  }
}

.navigationButtons {
  padding: rem(17) 1rem rem(40) 1rem;
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-style: normal;
    font-size: 1rem;
    text-decoration-line: underline;
    color: #2898ff;
  }
}

.adressMapContainer {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 30vh;
  @media screen and (max-height: $strange-tablet) {
    min-height: 25vh;
  }
  @media screen and (min-height: $tablet-landscape) {
    min-height: 40vh;
  }
  @media screen and (min-height: $desktop) {
    min-height: 50vh;
  }
}
