.propertyPageContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: #f8f8f9;
  z-index: 5;
  padding-bottom: 70px;
}

.propertyMapContainer {
  height: 50vh;
  width: 100%;
}
