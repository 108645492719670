.container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 2.8125rem 0;

  h2 {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    font-size: 1.25rem;
  }
}

.popup {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  overflow-y: scroll;
}

.closePopupButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 1.125rem 1.125rem 0.75rem 1.125rem;
}

.title {
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center;
    color: #333333;
  }
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigButtonContainer {
  padding: 1.625rem 1rem;
}

.haveAnAccount {
  padding: 0.9rem 2rem 2rem 2rem;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #34343f;
    text-align: center;
  }

  span {
    cursor: pointer;
    color: #2898ff;
    text-decoration: underline;
  }
}

.dividerWrapper {
  padding-bottom: 1.1875rem;
}
