@import "../../../mixins/pxrem.scss";

.container {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.accessItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #c0c0c0;
  border-radius: rem(8);
  width: 5rem;
  height: 4.5rem;
  margin-left: 1rem;
  padding: 0.625rem;
  transition: background-color 0.2s ease-in-out;

  h2 {
    pointer-events: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    color: #6b6f7b;
  }

  svg {
    pointer-events: none;
    user-select: none;

    path {
      fill: #c0c0c0;
    }
  }

  &_active {
    background-color: #f4f7ff;

    svg {
      path {
        fill: #2898ff;
      }
    }
  }

  @media (hover) {
    &:hover {
      background-color: #f4f7ff;
    }
  }
}
