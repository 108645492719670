@import "../../../mixins/pxrem.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.75rem 0.875rem;

  &_notFilter {
    padding: 0;

    .sortingItem {
      padding: 1rem 1.5rem;
    }
  }
}

.sortingItem {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #c0c0c0;
  background-color: transparent;
  border-radius: rem(8);
  padding: 0.5rem 1.5rem;
  width: 49%;
  margin-bottom: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #c0c0c0;
    white-space: nowrap;
    user-select: none;
    pointer-events: none;
  }

  &_active {
    background-color: #f4f7ff;

    h2 {
      color: #6b6f7b;
    }
  }

  @media (hover) {
    &:hover {
      background-color: #f4f7ff;
    }
  }
}
