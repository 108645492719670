@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.title {
  h2,
  h3 {
    transition: all 0.3s ease-in-out;
    text-align: center;
    font-style: normal;
  }

  h2 {
    font-weight: 400;
    font-size: 1.125rem;
    color: #333333;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #6b6f7b;
    padding-top: 0.5rem;
  }

  &_error {
    h2 {
      color: red;
    }
  }
}
