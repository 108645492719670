$tab_transform: translateX(0%);

.container {
  --transformAmount: translateX(0%);
  position: relative;
  color: #fff;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0 0 0;

  &::before {
    content: "";
    display: block;
    height: 4px;
    width: 50%;
    position: absolute;
    bottom: 0;
    background-color: #2898ff;
    transition: 0.3s;
    transform: var(--transformAmount);
  }
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.5rem;
  cursor: pointer;

  h2 {
    pointer-events: none;
    user-select: none;
    color: #6b6f7b;
    font-size: 0.875rem;
    padding-left: 0.5rem;
  }

  svg {
    pointer-events: none;
    user-select: none;
    path {
      fill: #757575;
    }

    circle {
      fill: #757575;
    }

    line {
      stroke: #757575;
    }
  }

  &_active {
    h2 {
      color: #2898ff;
    }

    svg {
      path {
        fill: #2898ff;
      }

      circle {
        fill: #2898ff;
      }

      line {
        stroke: #2898ff;
      }
    }
  }
}
