@import "../../mixins/pxrem.scss";

.container {
  padding: 1.1875rem 1.875rem;
  width: 100%;
  background: #fff;
  margin-top: 1.3125rem;

  a {
    text-decoration: none;
  }
}

.title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.875rem;

  h2 {
    color: #333333;
    font-weight: 400;
    font-size: 1rem;
  }
}

.pressReportsList {
  width: 100%;
}

.pressReportsItem {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    max-width: 10rem;
    min-width: 10rem;
    width: 100%;
    flex: 1 0;
    border-radius: rem(9);
    object-fit: cover;
  }

  h2,
  h3,
  h4 {
    color: #333333;
  }

  h2 {
    font-weight: 500;
    font-size: 0.875rem;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
  }

  h4 {
    font-style: italic;
    font-weight: 400;
    font-size: 0.625rem;
    padding-top: 0.5rem;
  }
}

.pressReportInfo {
  display: flex;
  flex-direction: column;
  padding-left: 1.359375rem;
  overflow-wrap: anywhere;
}

.divider {
  width: 100%;
  border: 1px solid #c0c0c0;
  margin: 1.84375rem 0 1.6875rem 0;
}

.reportPreview {
  width: 10rem;
  height: rem(91);
  max-width: 10rem;
  min-width: 10rem;
  flex: 1 0;
  border-radius: 0.5625rem;
  object-fit: cover;
}

.seeMoreButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #2898ff;
    padding-bottom: 0.5rem;
  }
}

.emptyReportsContainer {
  h2 {
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #c0c0c0;
    text-align: center;
  }
}
