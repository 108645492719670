@import "../../mixins/pxrem.scss";

.container {
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  z-index: 5;
}

.filtersContainer {
  background: #f8f8f9;
  display: flex;
  flex-direction: column;
}

.scrollWrapper {
  overflow: auto;
}

.filterItem {
  margin: 1rem 0.5rem;
}

.filteInfo {
  display: flex;
  padding-bottom: 0.4375rem;
  padding-left: 0.5rem;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #6b6f7b;
    padding-left: 0.375rem;
  }

  svg {
    height: 1rem;
  }
}

.content {
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
}

.applySave {
  width: 100%;
  padding: 1rem 1rem 3rem 1rem;
}

.dynamicTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.2rem;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

  h2,
  input {
    color: #2898ff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: rem(24);
    border: 1px solid transparent;
  }

  svg {
    height: rem(27);
    width: rem(26);
  }

  span {
    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  input {
    border: 1px solid #2898ff;
    padding: 0 rem(16);
    width: fit-content;
    text-align: center;
  }
}
