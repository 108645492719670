.progressBarContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.4375rem 1rem;
}

.progressBarItem {
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  border: 1px solid #2898ff;
  border-radius: 50%;

  &::after {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 50%;
  }

  &_active {
    border: none;

    &::after {
      background: #2898ff;
    }
  }
}

.progressItem {
  position: relative;
  flex: 1 0;
  height: 0.25rem;
  background: #d3dce1;

  &::after {
    transition: 0.8s;
    content: "";
    display: block;
    position: absolute;
    height: 0.25rem;
    background: #2898ff;
    width: 0%;
  }

  &_active {
    &::after {
      width: 100%;
    }
  }
}
