.PrivacyPolicy {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label,
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 0.6875rem;
    color: #34343f;
    user-select: none;
    text-align: center;
    padding-left: 0.5rem;
  }

  a {
    text-decoration: underline;
    pointer-events: auto;
    cursor: pointer;
    color: #34343f;
  }

  &_errored {
    label,
    a {
      color: red;
    }
  }
}
