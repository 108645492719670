.switchContainer {
  transition: 0.35s cubic-bezier(0.22, 0.31, 0.67, 0.62);
  overflow: hidden;
  width: 200%;
  display: flex;
  height: 100%;
  transform: translateX(0%);

  &_right {
    transform: translateX(-50%);
  }
}

.map {
  position: relative;
  height: 100%;
  width: 100%;
}

.cardList {
  height: 100%;
  width: 100%;
}
