.layoutContainer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 2;
}

.addListingWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.ListingPopup {
  pointer-events: auto;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  height: min-content;
  max-height: 100%;
}

.secondLineTitle {
  margin-top: 0.5rem;
}

.LogoWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.crossWrapper {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
}

.title {
  width: 100%;
  padding-top: 0.5625rem;
  h2 {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    color: #333333;
  }
}

.navigationButtons {
  padding: 0 1rem 1.375rem 1rem;
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-style: normal;
    font-size: 1rem;
    text-decoration-line: underline;
    color: #2898ff;
  }
}

.childerWrapper {
  padding: 0;
  height: 100%;
  overflow-y: scroll;
}
