.LoginButtonsList {
  padding: 0 1rem;
}

.LoginButtonItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #c1c5cc;
  padding: 0.75rem 0;
  background: #f4f7ff;
  border-radius: 3px;
  margin-bottom: 1rem;
  cursor: pointer;

  h2 {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 0.9375rem;
    color: #656565;
    font-style: normal;
    padding-left: 0.5rem;
  }

  svg,
  h2 {
    user-select: none;
    pointer-events: none;
  }
}
