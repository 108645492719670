@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.propertyInfoItem {
  padding-top: rem(28);

  textarea,
  input {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    font-size: rem(14);
    padding: rem(11) 0 rem(11) rem(13);
    transition: border 0.5s ease-in-out;

    &::placeholder {
      color: #c0c0c0;
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
    }
  }

  input[type="date"] {
    padding-right: rem(11);
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    resize: none;
    height: rem(126);
  }

  &_error {
    input,
    textarea {
      border: 1px solid red;
    }
  }
}

.PropertyInfoItemTitleWrapper {
  display: flex;
  padding-bottom: rem(4);
  padding-left: rem(4);

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: rem(14);
    color: #6b6f7b;
  }

  h3 {
    padding-left: 0.3rem;
    color: #ff647c;
  }
}

.dateInputContainer {
  position: relative;
}

.openButton {
  position: absolute;
  top: 0.5rem;
  bottom: 0;
  margin: auto;
  right: 0.6875rem;
  width: 3rem;
  height: 2rem;
  background: #fff;
  pointer-events: none;

  button {
    border: none;
    background: transparent;

    svg {
      width: rem(44);
      height: rem(25);
    }
  }
}
