@import "../../mixins/pxrem.scss";

.container {
  padding: 1em;
  margin: -1em;
}

.wrapper {
  width: fit-content;
  height: fit-content;
  background-color: #f4f7ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all !important;
  margin-left: 0 !important;

  svg {
    pointer-events: none;
    user-select: none;
    path {
      transition: fill 200ms ease-in-out;
      fill: #6b6f7b;
    }
  }

  &_active {
    svg {
      path {
        fill: #2898ff;
      }
    }
  }
}
