@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  display: flex;
  flex-shrink: 0;
  min-height: 10.5rem;
  width: 95%;
  min-width: 22.25rem;
  background-color: #ffff;
  border-radius: 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #333333;
    font-weight: 500;
    max-width: 8rem;
  }

  h1 {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 800;
  }

  h3 {
    font-size: 0.875rem;
  }

  h4 {
    font-size: 0.75rem;
  }

  h5 {
    font-size: 0.625rem;
  }

  &_atMap {
    padding: 0 1rem 0 0;
    border: 3px solid white;
  }
}

.preview {
  position: relative;
  flex: 1 0;

  img {
    pointer-events: none;
  }
}

.cardInfo {
  height: 100%;
  cursor: pointer;
  padding: rem(12) 0 rem(9) rem(16);

  &_atMap {
    padding: 0.625rem 0rem 1rem 1rem;
    width: 60%;
  }

  div {
    pointer-events: none;
  }
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;

  h2,
  h3 {
    color: #2898ff;
  }

  h2 {
    padding-right: 0.3125rem;
  }

  h3 {
    font-size: rem(12);
  }
}

.toolsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(12) rem(9) rem(4) 0;

  svg {
    width: rem(26);
    height: rem(27);
  }
}

.visibilityTools {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.svgContainer {
  cursor: pointer;

  svg {
    display: block;
    ellipse,
    path {
      transition: fill 0.2s ease-in-out;
    }

    @media (hover) {
      &:hover {
        ellipse {
          fill: #2898ff;
        }
        path {
          fill: #ffffff;
        }
        line {
          stroke: #ffffff;
        }
      }
    }
  }

  &_disabled {
    pointer-events: none;
    user-select: none;
    svg {
      ellipse {
        fill: #e5e5e5;
      }
      path {
        fill: #827a7a;
      }
      line {
        stroke: #827a7a;
      }
    }
  }
}

.hidingTool {
  padding-bottom: rem(6);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_atMap {
    padding-left: 0;

    h2 {
      overflow: hidden;
      font-size: 1.375rem;
    }
  }
}

.cardBody {
}

.infoItem {
  display: flex;
  align-items: flex-end;
  text-align: start;
  padding-bottom: 0.7rem;

  svg {
    align-self: flex-start;
    margin-right: 0.625rem;
  }

  &_area {
    h4 {
      max-height: 3.5rem;
      overflow: hidden;
    }
  }
}

.typeList {
  display: flex;
  width: 100%;
}

.typeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;

  h5 {
    color: #757575;
    font-weight: 600;
  }

  &:not(:first-child) {
    margin-left: 1rem;
  }

  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
  }
}

.typeItemFilled {
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 10px;

  &_lease {
    background: #eaf0ff;

    h5 {
      color: #2b67f6;
    }
  }

  &_sale {
    background: rgba(252, 206, 61, 0.4);

    h5 {
      color: #ff9635;
    }
  }

  &_sold {
    background: #dedee3;

    h5 {
      color: #5d5959;
    }
  }
}

.information {
  padding: rem(14) 0;
}

@media (max-width: 400px) {
  body,
  html {
    font-size: 14px;
  }
}

@media (max-width: 340px) {
  body,
  html {
    font-size: 10px;
  }
}

@media (max-width: 250px) {
  body,
  html {
    font-size: 8px;
  }
}

@media (max-width: 200px) {
  body,
  html {
    font-size: 6px;
  }
}
