.container {
  width: 100%;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #333333;
  }
}
