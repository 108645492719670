.container {
  margin-top: 1.625rem;
  width: 100%;
  background: #fff;

  padding: 1.1875rem 1.875rem;

  h2 {
    font-style: normal;
    font-weight: 400;
    color: #333333;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.875rem;

  h2 {
    font-size: 1rem;
  }
}

.informationItem {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  h2 {
    font-size: 0.875rem;
  }

  &:first-of-type {
    padding-top: 0rem;
  }
}
