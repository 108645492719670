@import "../../mixins/pxrem.scss";

.share {
  padding: 1.5em;
  margin: -1.5em;

  svg {
    display: block;
  }
}
