@import "../../mixins/pxrem.scss";

.watchList {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f8f8f9;
  padding-bottom: rem(62.88);
}

.list {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0;
  padding: 1.25rem 0 calc(3.125rem + 3.93rem) 0;
  overflow: scroll;
}

.listItem {
  display: flex;
  justify-content: center;
  width: 100%;
}
