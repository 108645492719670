@import "../../mixins/pxrem.scss";

.button {
  padding: rem(9) rem(80);
  border-radius: rem(15);

  h3 {
    font-size: rem(14);
    text-align: center;
  }

  &_yes {
    background-color: #2898ff;

    h3 {
      color: #ffffff;
    }
  }

  &_no {
    background-color: #ffffff;
    margin-top: rem(9);
    h3 {
      color: #2898ff;
    }
  }

  &_disabled {
    pointer-events: none;
    user-select: none;
    background-color: #e5e5e5;
    h3 {
      color: #ffffff;
    }
  }
}
