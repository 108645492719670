@import "../../mixins/pxrem.scss";

$box-shadow: 3px 5px 15px rgb(0 0 0 / 20%);

.container {
  position: relative;
  width: fit-content;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0.5px solid #6b6f7b;
  min-width: 5rem;

  h2 {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    font-family: "Poppins";
    color: #222222;
  }
}

.selector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    padding-right: 0.5rem;
    color: black;
  }

  svg {
    user-select: none;
    pointer-events: none;
    path {
      fill: black;
    }
  }
}

.dropdown {
  width: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 120%;
  background-color: #fff;
  box-shadow: $box-shadow;
  transition: opacity 0.1s ease-in-out;
  border-radius: 0.5rem;
  pointer-events: none;
  user-select: none;
  z-index: 10;

  &_opened {
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  color: black;
  transition: color 0.25s ease-in-out;

  &_active {
    color: #2898ff;
  }
}
