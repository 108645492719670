.container {
  width: 100%;
  background: #fff;
  padding: 1.1875rem 0;
  margin-top: 1.625rem;

  h2 {
    font-style: normal;
    font-weight: 400;
    color: #333333;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.875rem;

  h2 {
    font-size: 1rem;
  }
}

.historicalDataItemsWrapper {
  padding: 0 1.875rem;
}

.historyBlockItem {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  h2 {
    font-size: 0.875rem;
    text-align: right;
  }

  &:first-of-type {
    padding-top: 0rem;
  }
}

.divider {
  width: 100%;
  border: 1px solid #c0c0c0;
  margin: 1rem 0;
}

.seeMoreButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #2898ff;
    padding-bottom: 0.5rem;
  }

  &_active {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.noData {
  width: 100%;
  h3 {
    text-align: center;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #c0c0c0;
  }
}
