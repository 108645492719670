@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.dropdown {
  position: absolute;
  top: 2.5rem;
  right: 0rem;
  z-index: 999;
  background-color: #ffffff;
  border-radius: rem(10);
  box-shadow: 5px 15px 25px rgba(0, 0, 0, 0.45);

  h2 {
    color: #231f20;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 1rem;

  svg {
    margin-right: 0.5rem;
    min-height: unset;
    min-width: unset;
    height: 1rem;
    width: 1rem;
  }
}

.divider {
  width: 100%;
  border-top: 1px solid rgba(211, 220, 225, 0.4);
}
