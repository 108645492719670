.container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 2;
}

.backButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.125rem 1.125rem 0.75rem 1.125rem;
}

.description {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.8125rem 0 2.8125rem 0;

  h2 {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    font-size: 1.25rem;
  }
}

.popup {
  position: relative;
  background-color: #ffffff;
  overflow-y: scroll;
  border-radius: 10px 10px 0 0;
}

.title {
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center;
    color: #333333;
  }
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputsList {
  padding: 3.75rem 1rem;

  div {
    &:first-of-type {
      margin-bottom: 2.5625rem;
    }
  }
}

.bigButtonContainer {
  padding: 1.625rem 1rem;
}

.haveAnAccount {
  padding: 1.625rem 2rem 2rem 2rem;
  flex: 1 0;
  justify-content: center;
  display: flex;
  align-items: self-end;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #34343f;
    text-align: center;
  }

  span {
    color: #2898ff;
    text-decoration: underline;
  }
}
