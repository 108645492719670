@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.removeAccount {
  margin-bottom: 1rem;

  h2 {
    cursor: pointer;
    color: #ff647c;
    text-decoration: underline;
    font-size: rem(14);
  }
}
