@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.progressBarContainer {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 0 rem(20) rem(20) rem(20);

  h2,
  h3 {
    font-style: normal;
    font-weight: 400;
    color: #6b6f7b;
  }

  h2 {
    font-size: rem(14);
  }

  h3 {
    font-size: rem(10);
  }

  h4 {
    font-size: rem(10);
    color: #ff7575;
    font-weight: 400;
    max-width: 5rem;
    white-space: nowrap;
  }
}

.progressBarWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #6b6f7b;
  border-radius: 10px;
  padding: rem(10) rem(23) rem(10) rem(10);
}

.background {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #faffee;
  border-radius: 10px 10px 10px 10px;

  &_fullfiled {
    border-radius: 10px;
    width: 100%;
  }

  &_rejected {
    border-radius: 10px;
    width: 100%;
    background-color: #fff2ee;
  }
}

.fileInformation {
  padding-left: rem(30);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    padding-bottom: 0.5rem;
  }
}

.leftContent {
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.placeholder {
  height: 100%;
  max-width: 20%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.fileStatus {
  display: flex;
  align-items: center;

  h4 {
    padding-left: rem(8);
  }
}

.rightContent {
  z-index: 1;

  svg {
    &:last-child {
      margin-left: rem(15);
    }
  }
}
