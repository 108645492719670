.container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
}

.popup {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  overflow-y: scroll;
}

.closePopupButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 1.125rem 1.125rem 0.75rem 1.125rem;
}

.title {
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    text-align: center;
    color: #333333;
  }
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputsList {
  padding: 1.5rem 1rem;

  div {
    margin-bottom: 1rem;
  }
}

.basicOptions {
  padding: 0 1.2rem 0 1.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.forgotPassword {
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    text-decoration-line: underline;
    color: #2898ff;
    cursor: pointer;
  }
}

.bigButtonContainer {
  padding: 2.0625rem 1rem;
}

.createNewAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.0625rem 0 1.5rem 0;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #34343f;
  }

  span {
    cursor: pointer;
    color: #2898ff;
    text-decoration: underline;
  }
}

.error {
  color: red;
  font-size: 0.75rem;
  padding-left: 0.3rem;
}
