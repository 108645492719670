@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.popup {
  position: relative;
  width: 85%;
  max-width: rem(500);
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: rem(15);
  z-index: 9;
}

.close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: rem(12) rem(16);

  svg {
    min-height: unset;
    min-width: unset;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: rem(30) 0 0 0;

  h2 {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 500;
    line-height: rem(19);
    font-size: rem(16);
    color: #333333;
    text-align: center;
  }
}

.smallMargin {
  margin-top: 0.3rem;
  max-width: 90%;
}

.buttons {
  padding: rem(41) 0 rem(14) 0;
}
