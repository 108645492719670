.container {
  padding: 1.0625rem;
  background-color: #ffffff;
}

.infoItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 0.6875rem;

  &:last-of-type {
    padding-bottom: 0;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #333333;
  }

  h3 {
    color: #757575;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
  }

  &_alignStart {
    .svgWrapper {
      align-self: flex-start;
    }
  }
}

.svgWrapper {
  height: 3rem;
  width: 3rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.infoWrapper {
  padding-left: 2.0625rem;
  oveflow-wrap: anywhere;
  h2 {
    text-transform: capitalize;
  }
}

.typeBase {
  width: min-content;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 1.6875rem;
  margin-left: 0.5rem;
  border: 1px solid transparent;
  text-transform: capitalize;
  white-space: nowrap;
}

.lease {
  color: #2b67f6;
  background: rgba(43, 103, 246, 0.1);
}

.sale {
  background: rgba(252, 206, 61, 0.4);
  color: #ff9635;
}

.sold {
  background: #dedee3;

  h5 {
    color: #5d5959;
  }
}

.offmarket {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgb(0, 0, 0);
  h5 {
    color: rgb(0, 0, 0);
  }
}
