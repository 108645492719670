.content {
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -1.875rem;
}

.text {
  width: 80%;
  text-align: center;

  h2 {
    padding-bottom: 1rem;

    &:last-of-type {
      padding-bottom: 0.3rem;
    }
  }

  a {
    color: #2898ff;
  }
}

.logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 90%;
  }
}
