@import "../../mixins/pxrem.scss";

// not overlay
.sticky {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9;
  box-shadow: 0 -3px 5px -2px #aaa;
  overscroll-behavior: contain;

  h2,
  h3 {
    white-space: nowrap;
    font-style: normal;
  }

  h2 {
    font-size: rem(14);
    line-height: rem(22);
    font-weight: 600;
  }

  h3 {
    font-size: rem(12);
    font-weight: 400;
  }
}

.stickyContent {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(6) 1rem;
}

.stickyText {
}

.stickyButton {
  cursor: pointer;
  background-color: #2898ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.8rem;
  height: 3.5rem;
  h2 {
    color: #ffffff;
    font-size: 1rem;
  }
}

//overlay

.stickyOverlay {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  background-color: rgba(40, 152, 255, 0.1);

  h2 {
    text-align: center;
    color: #565555;
  }
}

.stickyOverlayPhoto {
  padding-bottom: 0.5rem;
  height: 10rem;
  width: 10rem;

  h2 {
    background-color: #d1d1d1;
    padding: 0.8rem 1rem;
    border-radius: 50%;
    font-size: 1rem;
  }

  img {
    height: 100%;
    border-radius: 50%;
  }
}

.stickyOverlayContent {
}

.stickyOverlayContentItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.3rem;

  h2 {
    padding-left: 0.5rem;
    font-size: 1.5rem;
    line-height: unset;
    font-weight: 400;
  }

  a {
    color: #2898ff;
    text-decoration: none;
  }

  &_name {
    padding-bottom: 0;
    h2 {
      text-transform: capitalize;
      padding-bottom: 0.1rem;
    }
  }
}

//skeleton
.stickySkeletonItem {
  height: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  span {
    height: 1.5rem;
    width: 100%;
  }

  &_small {
    width: 40%;
    padding-bottom: 0.5rem;
  }

  &_big {
    width: 70%;
    padding-bottom: 0.3rem;
  }
}

//no owner

.noOwner {
  width: 100%;
  padding: 2rem 0;
  h3 {
    text-align: center;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #c0c0c0;
  }
}

.stickyOverlayContactButtonsContainer {
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  gap: 1rem;
}

.stickyOverlayContactButton {
  display: block;
  background-color: #2898ff;
  padding: 1rem 2.5rem;
  border-radius: 0.4rem;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);

  svg {
    height: 2rem;
    width: 2rem;
    path {
      fill: #ffffff;
    }
  }
}
