@import "../../mixins/pxrem.scss";

.container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.625rem 0.875rem;

  input {
    flex: 1 0;
    margin: 0 0.75rem;
    padding: 0 3rem 0 2rem;
    background-color: #f4f7ff;
    border: 1px solid transparent;
    border-radius: rem(10);
    min-width: 0;
    box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
    border: 0.5px solid rgba(0, 0, 0, 0.03);
  }

  button {
    width: fit-content;
    border: none;
    background-color: transparent;

    h2 {
      color: #6b6f7b;
      font-size: 0.75rem;
      white-space: nowrap;
    }
  }
}

.mobileLogoContainer {
  cursor: pointer;

  svg {
    width: rem(42);
    height: rem(34);
  }
}

.search {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  svg {
    position: absolute;
    height: 1.125rem;
    width: 1.125rem;
    right: 2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    min-height: auto;
    min-width: auto;
  }
}

.searchContainer {
  position: relative;
  width: 100%;
}

.searchResults {
  border-radius: 10px;
  max-height: 50vh;
  overflow-y: scroll;
  left: 0;
  top: 50px;
  z-index: 2;
  position: absolute;
  width: 100%;
  box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0 0 0 rem(23);
  list-style: none;
  background: #ffffff;
  li {
    padding-bottom: rem(20);
    &:first-of-type {
      padding-top: rem(20);
    }
  }
}
