.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2;
}

.title {
  width: 100%;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    color: #333333;
  }
}

.emailCircleWrapper {
  padding: 2.5625rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  animation: scaling 5s infinite ease-in-out;
}

@keyframes scaling {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.titleSmall {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    font-weight: 400;
    color: #333333;
    font-style: normal;
    font-size: 1.125rem;
  }
}

.bigButtonContainer {
  padding: 3.8125rem 1rem;
}

.haveAnAccount {
  padding: 1.625rem 2rem 2rem 2rem;
  flex: 1 0;
  justify-content: center;
  display: flex;
  align-items: self-end;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #34343f;
    text-align: center;
  }

  span {
    color: #2898ff;
    text-decoration: underline;
  }
}
