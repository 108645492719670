.container {
  position: relative;
  z-index: 2;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1rem 0rem;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
}

.navigationItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  cursor: pointer;

  h2 {
    transition: all 0.2s ease-in-out;
    color: #6b6f7b;
    font-size: 0.625rem;
    margin-top: 0.3125rem;
    pointer-events: none;
    user-select: none;
  }

  svg {
    pointer-events: none;
    path {
      transition: all 0.2s ease-in-out;
    }
  }

  //TODO WE NEED IT LATER
  &_modied {
    //   svg {
    //     pointer-events: all;
    //     position: absolute;
    //     bottom: 1.8rem;
    //   }

    //   h2 {
    //     margin-top: 1.5rem;
    //   }
  }

  &_active {
    h2 {
      color: #2898ff;
    }

    svg {
      path {
        transition: all 0.2s ease-in-out;
        fill: #2898ff;
      }
    }
    //TODO REMOVE LATER
    &.navigationItem {
      &_modied {
        svg {
          path {
            fill: unset;
            stroke: #2898ff;
          }
        }
      }
    }
  }

  @media (hover) {
    &:hover {
      h2 {
        color: #2898ff;
      }

      svg {
        path {
          fill: #2898ff;
        }
      }

      &.navigationItem {
        &_modied {
          svg {
            path {
              fill: unset;
              stroke: #2898ff;
            }
          }
        }
      }
    }
  }
}
