.saveSearchButton {
  border: 1px solid #2898ff;
  background-color: #2898ff;
  border-radius: 8px;
  padding: 0.3rem 0.6rem;
  margin: 0.75rem 0rem 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  h2 {
    color: #fff;
    font-weight: 500;
    font-size: 0.75rem;
    white-space: nowrap;
    padding: 0rem 0.4375rem 0rem 0.4375rem;
    pointer-events: none;
    user-select: none;
    text-transform: capitalize;
    font-family: "Poppins";
    user-select: none;
    pointer-events: none;
  }
}
