.backButton {
  cursor: pointer;

  h2 {
    font-size: 1rem;
    font-weight: 600;
    text-decoration-line: underline;
    color: #2898ff;
  }
}
