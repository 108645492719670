@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.navigationButtons {
  padding: 0 1rem 1.375rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonGroup {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  justify-content: center;
  padding: rem(34) rem(10) rem(43) rem(10);

  h2 {
    font-size: rem(20);
    font-weight: 500;
    font-style: normal;
    text-align: center;
  }
}

.leaseButton {
  padding: 1rem rem(30);
  background: rgba(43, 103, 246, 0.3);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;

  h2 {
    color: #2b67f6;
  }

  &_active {
    border: 2px solid rgba(43, 103, 246, 1);
  }
}

.SaleButton {
  padding: 1rem rem(30);
  background: rgba(252, 206, 61, 0.6);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: border 0.25s ease-in-out;

  h2 {
    color: #6b6f7b;
  }

  &_active {
    border: 2px solid rgba(43, 103, 246, 1);
  }
}
