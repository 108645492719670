.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.625rem;
  background: #fff;

  padding: 1.1875rem 1.875rem;

  h2 {
    font-style: normal;
    font-weight: 400;
    color: #333333;
    font-size: 1rem;
    overflow-wrap: anywhere;
  }
}

.description {
  padding-top: 1.875rem;
  text-align: left;
  width: 100%;
}

.noDescription {
  h2 {
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #c0c0c0;
    text-align: center;
  }
}
