.bubbleContainer {
  position: relative;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #f8f8f9;
    pointer-events: none;
  }

  &_sale {
    svg {
      path {
        fill: #fcce3d;
      }
    }
    h2 {
      color: #757575;
    }
  }

  &_lease {
    svg {
      path {
        fill: #2898ff;
      }
    }
    h2 {
      color: #ffffff;
    }
  }

  &_sold {
    svg {
      path {
        fill: #afaeaf;
      }
    }
    h2 {
      color: #ffffff;
    }
  }
}

.textContainer {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 35%;
  left: 0;
  text-align: center;
}
