@import "../../mixins/pxrem.scss";

.deltaContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 0rem rem(10) rem(32) 0;
  scroll-behavior: smooth;

  h2 {
    transition: all 0.3s ease-in-out;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(21);
    text-align: center;
    user-select: none;
    color: #c0c0c0;
    white-space: nowrap;
  }
}

.deltaItem {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  border: 1px solid #c0c0c0;
  border-radius: rem(8);
  padding: 0.7rem 1.2rem;
  margin-left: rem(8);
  min-width: 7rem;
  background-color: transparent;

  &_active {
    background-color: #f4f7ff;

    h2 {
      color: #6b6f7b;
    }
  }

  &:first-of-type {
    margin-left: 0;
  }
}
