@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  width: 100%;

  h2,
  h3 {
    user-select: none;
  }
}

.propertiesList {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
  padding: 1rem;
}

.propertyListItem {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0 0.4375rem 0;
  width: 25%;
}

.propertyListItemPreview {
  position: relative;
  border-radius: rem(9);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1;
  pointer-events: none;
}

.previewOutline {
  position: absolute;
  height: 45%;
  width: 100%;
  z-index: 1;

  &_top {
    top: 0;
    border-radius: rem(9) rem(9) 0 0;
    background: linear-gradient(0deg, rgba(51, 51, 51, 0) 0%, #333333 92.65%);
  }

  &_bottom {
    bottom: 0;
    border-radius: 0 0 rem(9) rem(9);
    background: linear-gradient(180deg, rgba(51, 51, 51, 0) 3%, #333333 92.65%);
  }
}

.price {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1vw;
  pointer-events: none;

  h2,
  h3 {
    color: #ffffff;
    font-size: 3vw;
    z-index: 5;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    align-self: flex-start;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    align-self: flex-end;
  }

  &_sale {
    h2 {
      padding-bottom: 15%;
    }
  }
}

.propertyListItemDescription {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #333333;
    padding-left: 0.3rem;
    text-transform: capitalize;
  }
}

.noSimmilar {
  width: 100%;
  background-color: #ffffff;
  padding: 2rem;

  h3 {
    text-align: center;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #c0c0c0;
  }
}
