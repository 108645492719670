.bigButton {
  background: rgba(40, 152, 255, 0.8);
  border-radius: 10px;
  width: 100%;
  padding: 0.8125rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  h2 {
    font-weight: 500;
    font-size: 1.25rem;
    color: #492121;
    font-style: normal;
    user-select: none;
    pointer-events: none;
    color: #ffffff;
  }
}
