@import "../../mixins/pxrem.scss";

.container {
  display: flex;
  flex-shrink: 0;
  min-height: 10.5rem;
  width: 95%;
  min-width: 22.25rem;
  background-color: #ffff;
  border-radius: rem(20);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #333333;
    font-weight: 500;
    max-width: 15rem;
  }

  h1 {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 800;
  }

  h3 {
    font-size: 0.875rem;
  }

  h4 {
    font-size: 0.75rem;
  }

  h5 {
    font-size: 0.625rem;
  }

  &_atMap {
    padding: 0 1rem 0 0;
    border: 3px solid white;
  }
}

.cardHeader {
  display: flex;
  padding: rem(22) 0 0 rem(22);
}

.cardIcon {
  background-color: #f4f7ff;
  border: 1px solid #c0c0c0;
  border-radius: rem(8);
  padding: rem(25);
  height: min-content;
}

.cardBody {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cardBodyHeader {
  display: flex;
  flex: 1 0;
  justify-content: flex-end;
  width: 100%;
}

.toolsContainer {
  height: 100%;
  display: flex;
  padding: rem(12) rem(9) rem(4) 0;

  svg {
    width: rem(26);
    height: rem(27);

    &:first-child {
      margin-right: rem(10);
    }
  }
}

.cardBodyContent {
  width: 100%;
  padding-left: rem(18);

  h2 {
    font-style: normal;
    font-size: 1rem;
    max-width: 100%;
  }
}

.address {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: rem(12);
    padding-left: rem(5);
  }

  svg {
    height: rem(18);
  }
}

.cardBodyFooter {
  height: 100%;
  display: flex;
  align-items: center;
  padding: rem(12) 0 rem(22) 0;
}

.viewProperties {
  border: 1px solid #2898ff;
  border-radius: rem(15);
  padding: rem(10) 1rem;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  h2 {
    color: #2898ff;
    text-align: center;
    font-size: rem(12);
    font-weight: 500;
    font-family: "Poppins";
    font-style: normal;
  }

  @media (hover) {
    &:hover {
      background-color: rgba(40, 152, 255, 1);
      h2 {
        color: #ffffff;
      }
    }
  }
}
