@import '../../mixins/pxrem.scss';

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f8f8f9;
  padding-bottom: rem(62.88);
}

.cardsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0;
  padding: rem(20) 0 calc(rem(50) + rem(62.88)) 0;
  overflow-y: scroll;
}

.searchItem {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: rem(30);

  &:last-of-type {
    padding-bottom: 0;
  }
}
