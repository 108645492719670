.yearBuiltFilterWrapper {
  display: flex;
  flex-direction: column;
  padding: 4rem 3.1875rem;

  .min-max-controls {
    padding-top: 3rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    input {
      min-width: none;
      width: 50%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border: 1px solid #c0c0c0;
      padding: 0.75rem 0.5rem;
      border-radius: 9px;
      font-size: 1rem;
      color: #757575;
    }

    span {
      padding: 0 0.4375rem;
      font-size: 1rem;
    }
  }

  .radioSetters {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
  }

  .radioSetterItem {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row;

    h2 {
      padding-left: 1rem;
      font-weight: 500;
      font-size: 1rem;
      font-style: normal;
      color: #6b6f7b;
      cursor: pointer;
    }
  }

  .resetButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      text-decoration-line: underline;
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
      color: #2898ff;
      padding-top: 2rem;
      cursor: pointer;
    }
  }

  .slider-area {
    display: flex;
    align-items: center;
    width: 100%;

    .noUi-target {
      flex: 1;
      position: relative;
      background: #e3e3e3;

      &.noUi-horizontal {
        height: 0.5rem;
      }

      .noUi-base,
      .noUi-connects {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
      }

      .noUi-connects {
        border-radius: 3px;
        overflow: hidden;
        z-index: 0;

        .noUi-connect {
          height: 100%;
          width: 100%;
          transform-style: preserve-3d;
          transform-origin: 0 0;
          transform-style: flat;
          background-color: #2898ff;
        }
      }

      .noUi-origin {
        will-change: transform;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        height: 0;
        width: 100%;

        .noUi-handle {
          border-radius: 50px;
          background: #fff;
          cursor: default;
          background-color: #fff;
          border: 1px solid #2898ff;
          backface-visibility: hidden;
          position: absolute;
          width: 1.1rem;
          height: 1.1rem;
          right: -0.625rem;
          top: -0.28125rem;

          .noUi-touch-area {
            cursor: pointer;
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .noUi-draggable {
      cursor: ew-resize;
    }

    .noUi-value,
    .noUi-value-horizontal,
    .noUi-value-sub {
      display: none;
    }
  }
}
