.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 30vh;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #ffffff;
  }
}

.controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.25rem 0 1.25rem;
  z-index: 2;
}

.alignmentRight {
  display: flex;
  align-items: center;

  div {
    margin-left: 1.375rem;
  }
}

.alignmentLeft {
  padding: 1.5em;
  margin: -1.5em;

  svg {
    path {
      transition: stroke 0.2s ease-in-out;
    }
  }

  @media (hover) {
    &:hover {
      svg {
        path {
          stroke: #2898ff;
        }
      }
    }
  }
}

.arrowControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  pointer-events: all;

  svg {
    scale: 1.2;
  }
}

.information {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.25rem 1.25rem 1.25rem;
  z-index: 2;
}

.bold {
  font-weight: 700;
}

.small {
  font-size: 0.875rem;
}

.bottomOutline {
  bottom: -15%;
}

.topOutline {
  top: -15%;
  transform: rotate(-180deg);
}

.topOutline,
.bottomOutline {
  position: absolute;
  height: 50%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333333 92.65%);
}
