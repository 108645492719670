@import "../../mixins/pxrem.scss";

.container {
  display: flex;
  flex-shrink: 0;
  padding: 0.625rem 1rem 1rem 1rem;
  min-height: 9.5rem;
  width: 95%;
  min-width: 22.25rem;
  background-color: #ffff;
  border-radius: 10px 20px 20px 10px;
  margin-top: 0.5rem;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #333333;
    font-weight: 500;
    max-width: 8rem;
  }

  h1 {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 800;
  }

  h3 {
    font-size: 0.875rem;
  }

  h4 {
    font-size: 0.75rem;
  }

  h5 {
    font-size: 0.625rem;
  }

  &_atMap {
    padding: 0 1rem 0 0;
  }

  &_sale {
    background-color: #f7f2d0;
  }

  &_lease {
    background-color: rgba(234, 240, 255, 0.7);
  }

  &_sold {
    background-color: rgba(222, 222, 227, 0.7);
  }

  &_offmarket {
    background-color: rgb(255, 255, 255);
  }
}

.preview {
  position: relative;
  flex: 1 0;

  h1 {
    white-space: nowrap;
    padding-bottom: 0.5rem;
  }
}

.cardInfo {
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  &_atMap {
    padding: 0.625rem 0rem 1rem 1rem;
    width: 60%;
  }

  div {
    pointer-events: none;
  }
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 8rem;

  h2 {
    padding-right: 0.3125rem;
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;

  .like {
    padding-left: 1rem;
  }

  h2 {
    font-size: 1.375rem;
  }
}

.cardBody {
}

.infoItem {
  display: flex;
  align-items: flex-end;
  text-align: start;
  padding-bottom: 0.7rem;

  svg {
    align-self: flex-start;
    margin-right: 0.625rem;
    width: rem(15);
  }

  &_area {
    h4 {
      max-height: 3.5rem;
      overflow: hidden;
    }
  }
}

.typeList {
  display: flex;
  width: 100%;
}

.typeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;

  h5 {
    color: #757575;
    font-weight: 600;
  }

  &:not(:first-child) {
    margin-left: 1rem;
  }

  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
  }
}

.typeItemFilled {
  padding: 0.3rem 1rem 0.3rem 1rem;
  border-radius: 10px;

  &_lease {
    background: #eaf0ff;
    h5 {
      color: #2b67f6;
    }
  }
  &_sale {
    background: rgba(252, 206, 61, 0.3);
    h5 {
      color: #300d0d;
    }
  }
  &_sold {
    background: #dedee3;
    h5 {
      color: #5d5959;
    }
  }
  &_offmarket {
    background: rgba(255, 255, 255, 1);
    h5 {
      color: rgb(0, 0, 0);
    }
  }

  &_bordered {
    &_lease {
      border: 1px solid #2b67f6;
    }
    &_sale {
      border: 1px solid #300d0d;
    }
    &_sold {
      border: 1px solid #5d5959;
    }
    &_offmarket {
      border: 1px solid rgb(0, 0, 0);
    }
  }
}

@media (max-width: 400px) {
  body,
  html {
    font-size: 14px;
  }
}

@media (max-width: 340px) {
  body,
  html {
    font-size: 10px;
  }
}

@media (max-width: 250px) {
  body,
  html {
    font-size: 8px;
  }
}

@media (max-width: 200px) {
  body,
  html {
    font-size: 6px;
  }
}
