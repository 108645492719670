.container {
  background: #f8f8f9;
  display: flex;
  flex-direction: column;
}

.scrollWrapper {
  overflow: auto;
}

.filterItem {
  margin: 1rem 0.5rem;
}

.filteInfo {
  display: flex;
  padding-bottom: 0.4375rem;
  padding-left: 0.5rem;

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #6b6f7b;
    padding-left: 0.375rem;
  }
}

.content {
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
}

.applySave {
  width: 100%;
  display: flex;
  padding: 1rem 0.5rem 3rem 0.5rem;

  div {
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.resetFilters {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 2rem 1rem 0 1rem;

  h2 {
    text-decoration-line: underline;
    font-size: 1rem;
    font-weight: 600;
    font-style: normal;
    color: #2898ff;
    cursor: pointer;
  }
}
