@import "../../mixins/pxrem.scss";

.container {
  position: relative;
  width: 100%;
  background: #ffffff;
  padding-bottom: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #757575;
  }
}

.sort {
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    color: #2898ff;
  }
}

.arrow {
  svg {
    display: block;
    margin-left: 0.5rem;
    transition: all 0.3s ease-in-out;
  }

  &_active {
    svg {
      transform: rotate(-180deg);
    }
  }
}
