.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f9;
  padding: 0 0.625rem 1.25rem 0.625rem;
  overflow-y: scroll;
}
