.container {
  position: relative;
  height: 6.25rem;
  width: 8.125rem;
  background: #f4f7ff;
  border: 1px solid #2898ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.4375rem;
  transition: all 0.2s ease-in-out;

  h2 {
    color: #2898ff;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    padding-top: 0.625rem;
    transition: all 0.2s ease-in-out;
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  svg {
    width: 2.6875rem;
    height: 2.375rem;
    path {
      transition: all 0.2s ease-in-out;
    }
  }

  &_posting {
    pointer-events: none;
    border: 1px solid #e5e5e5;

    h2 {
      color: #e5e5e5;
    }

    svg {
      path {
        fill: #e5e5e5;
      }
    }
  }
}
