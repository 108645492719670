$blue: #2898ff;
$white: #ffffff;
$grey: #cccccc;

.link {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: $white;
  background: $blue;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  &_disabled {
    color: $grey;
    cursor: unset;
    border: 1px solid $grey;
    background: transparent;
  }

  &_secondary {
    color: $blue;
    background: transparent;
    border: 1px solid $blue;
    transition: background 200ms ease-in-out, color 200ms ease-in-out;

    @media (hover) {
      &:hover {
        color: $white;
        background: $blue;
      }
    }
  }
}
