@import "../../../mixins/pxrem.scss";

.container {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  justify-content: center;
  grid-gap: 1rem;
  width: 100%;
  padding: 2rem 1rem;
}

.cardBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.625rem;
  width: 5rem;
  margin: auto;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #c0c0c0;
  border-radius: rem(8);
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap;

  h2 {
    pointer-events: none;
    color: #333333;
    font-weight: 400;
    max-width: 8rem;
    font-size: 0.625rem;
    padding-top: 0.6875rem;
  }

  svg {
    pointer-events: none;
    height: 2rem;
    width: 2rem;

    path {
      fill: #c0c0c0;
    }
  }

  &_active {
    background-color: #f4f7ff;

    svg {
      path {
        fill: #2898ff;
      }
    }
  }

  @media (hover) {
    &:hover {
      background-color: #f4f7ff;
    }
  }
}

.additionalOptions {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 2rem 1rem;

  h2 {
    color: #333333;
    font-weight: 400;
    font-size: 1rem;
    margin-left: 0.8rem;
    white-space: nowrap;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #2898ff;
    font-size: 1rem;
  }
}

.chooseAllProperties {
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
  }
}
