.MapMobileSliderContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;

  .swiper {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
  }

  .swiper-container {
    border-radius: 15px !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
