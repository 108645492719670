.bubbleContainer {
  position: relative;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #f8f8f9;
    pointer-events: none;
  }

  svg {
    path {
      transition: 250ms ease-in-out;
      transition-property: fill, stroke;
    }
  }

  h2 {
    transition: color 0.3s ease-in-out;
  }

  &_lease {
    svg {
      path {
        fill: #2898ff;
      }
    }
    h2 {
      color: #ffffff;
    }
  }

  &_sale {
    svg {
      path {
        fill: #fcce3d;
      }
    }
    h2 {
      color: #757575;
    }
  }

  &_sold {
    svg {
      path {
        fill: #afaeaf;
      }
    }
    h2 {
      color: #ffffff;
    }
  }

  &_visited {
    &.bubbleContainer {
      &_lease {
        svg {
          path {
            fill: #9ed1ff;
          }
        }
      }

      &_sale {
        svg {
          path {
            fill: #ffebab;
          }
        }
      }

      &_sold {
        svg {
          path {
            fill: #dedede;
          }
        }
      }
    }
  }

  &_active {
    transform: scale(1.1);
    svg {
      path {
        stroke: black;
      }
    }
  }
}

.textContainer {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 35%;
  left: 0;
  text-align: center;
  pointer-events: none;
}
