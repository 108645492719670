@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.navigationButtons {
  padding: 0 1rem 1.375rem 1rem;
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-style: normal;
    font-size: 1rem;
    text-decoration-line: underline;
    color: #2898ff;
  }
}

.propertyInfoWrapper {
  padding: rem(27) rem(22) 0 rem(18);
}

.propertyInfoItem {
  padding-top: rem(28);

  textarea,
  input[type="text"] {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    padding: rem(11) 0 rem(11) rem(13);

    &::placeholder {
      color: #c0c0c0;
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
    }
  }

  textarea {
    resize: none;
    height: rem(126);
  }
}

.PropertyInfoItemTitleWrapper {
  padding-bottom: rem(4);
  padding-left: rem(4);
  display: flex;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: rem(14);
    color: #6b6f7b;
  }

  span {
    padding-left: 0.3rem;
    color: red;
  }

  &_error {
    h2 {
      color: red;
    }
  }
}
