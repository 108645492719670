.absoluteContainer {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0;
  left: 0;

  .swiper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .swiper-container {
    border-radius: 15px !important;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background-color: #ffffff;
  }

  .swiper-button-next {
    background-image: url("./nextButton.svg") !important;
    background-repeat: no-repeat;
    --swiper-navigation-size: 16px;
    color: #ffffff;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    background-image: url("./prevButton.svg") !important;
    background-repeat: no-repeat;
    --swiper-navigation-size: 16px;
    color: #ffffff;
  }

  .swiper-button-prev::after {
    display: none;
  }
}

.absoluteContainerAtMap {
  height: 100%;

  .swiper {
    border-radius: 18px 0px 0px 18px;
  }
}

.absoluteContainerFull {
  height: 100%;
}
