@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.button {
  position: sticky;
  height: 3rem;
  width: 5rem;
  top: calc(50vh - rem(25));
  left: calc(50vw - rem(100));
  background-image: none;
  border: none;
  outline: none;
  background-color: #2898ff;
  color: white;
  border-radius: rem(10);
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.button:active {
  background-color: #2898ff;
}

.loading {
  border-radius: rem(50);
  width: rem(50);
  left: calc(50vw - rem(25));
}
.button.loading::after {
  width: 40px;
  left: 5px;
  top: 12px;
  border-radius: 100%;
}
.spinner {
  display: block;
  width: rem(34);
  height: rem(34);
  position: absolute;
  top: rem(8);
  left: calc(50% - rem(17));
  background: transparent;
  box-sizing: border-box;
  border-top: rem(4) solid white;
  border-left: rem(4) solid transparent;
  border-right: rem(4) solid transparent;
  border-bottom: rem(4) solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
