.container {
  background-color: #fff;
}

.scrollableContainer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: space-between;
  padding: 0 1rem;
}

.optionFilters {
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top;
  width: 100%;
  max-height: calc(100% - 4.3125rem - 4.3125rem - 2.375rem);
  overflow-y: scroll;
  position: absolute;
  transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
  background: #fff;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 4px 4px rgba(6, 5, 5, 0.25);
  z-index: 2;

  &_active {
    transform: scaleY(1);
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .optionFilters {
    width: fit-content;
  }
}

.filters {
  height: 100%;
  overflow: hidden;
}

.option {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #6b6f7b;
  border-radius: 8px;
  padding: 0.3rem 0.6rem;
  width: fit-content;
  margin: 0.75rem 0rem 0.75rem 1rem;
  cursor: pointer;

  &:first-child {
    margin: 0.75rem 0rem 0.75rem 0rem;
  }

  h2 {
    color: #6b6f7b;
    font-weight: 500;
    font-size: 0.75rem;
    white-space: nowrap;
    padding: 0rem 0.4375rem 0rem 0.4375rem;
    pointer-events: none;
    user-select: none;
    text-transform: capitalize;
    font-family: "Poppins";
  }

  svg {
    pointer-events: none;
    user-select: none;

    path {
      fill: #6b6f7b;
      stroke: #6b6f7b;
    }
  }

  &_active {
    border: 1px solid #2898ff;
    background-color: #2898ff;

    h2 {
      color: #fff;
    }

    svg {
      path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
}

.filterOptions {
  display: flex;
}

.searchOptions {
  display: flex;
}

.dropdownIcon {
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
  display: flex;
  justify-content: center;
  align-items: center;

  &_active {
    transform: rotate(-180deg);
  }
}
