@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}
 a {
    text-decoration: underline;
    pointer-events: auto;
    cursor: pointer;
    color: #34343f;
  }
.navigationButtons {
  padding: 0 1rem 1.375rem 1rem;
  display: flex;
  justify-content: space-between;
  padding-top: rem(44);
  h2 {
    font-weight: 600;
    font-style: normal;
    font-size: 1rem;
    text-decoration-line: underline;
    color: #2898ff;
  }
}

.title {
  h2,
  h3 {
    text-align: center;
    font-style: normal;
  }

  h2 {
    font-weight: 400;
    font-size: 1.125rem;
    color: #333333;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #6b6f7b;
    padding-top: 0.5rem;
  }
}

.uploadPhotoContainer {
  padding: rem(40) rem(20) rem(20) rem(20);
}

.uploadPhotoWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed #2898ff;
  border-radius: 20px;
  background: rgba(40, 152, 255, 0.02);
  padding: 3rem 0;

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
}

.uploadDocumentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed #2898ff;
  border-radius: 20px;
  background: rgba(40, 152, 255, 0.02);

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
}

.uploadPhotoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(40) 0 rem(41) 0;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: rem(14);
    color: #6b6f7b;
    padding-top: rem(14);
  }
}

.privacyPolicy {
  width: 100%;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: rem(11);
    text-align: center;
    color: #34343f;
    padding-top: rem(7);
  }

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.uploadDocumentPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: rem(120) 0;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: rem(14);
    color: #6b6f7b;
    padding-top: rem(2);

    &:first-of-type {
      padding-top: rem(14);
    }
  }
}
