.TermsOfServiceWrapper {
  height: 100%;
  overflow-y: scroll;

  .TermsOfService {
    padding: 1rem;

    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }

    & h3 {
      font-weight: bold;
      text-decoration: underline;
    }

    & li {
      margin-left: 2rem;
    }

    & a {
      color: #007bff !important;
    }
  }
}
