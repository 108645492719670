@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  position: relative;
  background-color: #f4f7ff;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 5%);
  cursor: pointer;

  h2 {
    font-size: rem(14);
    font-weight: 500;
  }

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
