.divider {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #6b6f7b;
    padding-left: 0.3rem;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    path {
      fill: #6b6f7b;
    }
  }
}
