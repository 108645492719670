.ellipseSvg {
  cursor: pointer;
  svg {
    display: block;
    ellipse,
    path {
      transition: fill 0.2s ease-in-out;
    }

    @media (hover) {
      &:hover {
        ellipse {
          fill: #2898ff;
        }
        path {
          fill: #ffffff;
        }
        line {
          stroke: #ffffff;
        }
      }
    }
  }
}
