.container {
  display: flex;
  overflow-x: scroll;
  padding: 0.4375rem 0.4375rem 0.4375rem 0;
  background-color: #ffffff;
}

.sliderItem {
  flex-shrink: 0;
  height: 6.25rem;
  width: 8.125rem;
  margin-left: 0.625rem;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    pointer-events: none;
  }
}
