@function rem($px) {
  $browser-context: 16;
  @return ($px/$browser-context) + rem;
}

.container {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: rem(24) rem(17);
  box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);

  h2 {
    font-style: normal;
    flex: 1 0;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #2898ff;
  }
}

.backArrow {
  position: absolute;
  cursor: pointer;

  svg {
    path {
      transition: stroke 0.3s ease-in-out;
      stroke: black;
    }
  }

  @media (hover) {
    &:hover {
      svg {
        path {
          stroke: #2898ff;
        }
      }
    }
  }
}
