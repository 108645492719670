@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
  outline: none;
  font-family: "Albert Sans";
}

input {
  user-select: auto;
}

input[type="radio"] {
  position: relative;
  transform: translateY(-0.075rem);
  display: grid;
  place-content: center;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid #90939c;
  border-radius: 50%;
}

input[type="radio"]::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 15px;
  background-color: #6b6f7b;
  content: "";
  display: inline-block;
  visibility: visible;
  transition: 120ms opacity ease-in-out;
  opacity: 0;
}

input[type="radio"]:checked::after {
  opacity: 1;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  padding-bottom: env(safe-area-inset-bottom);
}

.App {
  height: 100%;
  width: 100%;
}

.switchContainer {
  overflow: hidden;
  width: 200%;
  display: flex;
  height: 100%;
  transform: translateX(-50%);
}

#root {
  height: 100%;
  width: 100%;
}

.mapbox-switch-view-control-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px !important;
  width: 50px !important;
  background: #fff;
  border-radius: 8px !important;
  margin-right: 0.5rem;
  margin-top: 16px;
  pointer-events: all;
  cursor: pointer;
}

.mapbox-switch-view-control-container:hover,
.mapboxgl-ctrl:hover {
  scale: 1.1;
}

.mapbox-switch-view-control-container,
.mapboxgl-ctrl {
  transition: scale 0.2s ease-in-out;
}

.mapboxgl-ctrl-geolocate span {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.27 6.73L13.03 16.86L11.39 12.61L10.57 12.29L7.14 10.96L17.27 6.73ZM21 3L3 10.53V11.51L9.84 14.16L12.48 21H13.46L21 3Z' fill='%23757575'/%3E%3C/svg%3E") !important;
}

.mapboxgl-ctrl-top-right div {
  height: 50px !important;
  width: 50px !important;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: transparent !important;
}

.mapboxgl-marker svg {
  pointer-events: none;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group:nth-of-type(3) {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto !important;
  background-color: transparent;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group:nth-of-type(3):hover {
  scale: 1;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group:nth-of-type(3) button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px !important;
  width: 50px !important;
  background: #fff;
  border: none;
  border-radius: 8px !important;
  pointer-events: all;
  cursor: pointer;
  transition: scale 0.2s ease-in-out;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group:nth-of-type(3) button .mapboxgl-ctrl-icon {
  filter: invert(48%) sepia(0%) saturate(1%) hue-rotate(335deg) brightness(95%) contrast(90%);
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group:nth-of-type(3) button:not(:disabled):hover {
  background-color: #fff !important;
  scale: 1.1;
}
