$delays: 0, -0.15s, -0.3s;

.mapLoading {
  white-space: nowrap;
  position: absolute;
  margin-top: 1rem;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.dotContainer {
  height: 40px;
  flex-direction: row;
  border-radius: 48px;
  background: #ffffff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.dotButton {
  background: none;
  border: 2px solid transparent;
  padding: 0 14px;
  border-radius: 8px;
  align-items: center;
  display: inline-flex;
  height: 100%;
  width: 100%;
  color: #222222;
}

.dotWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 40px;
}

.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  background-color: #222222;
  margin: 0 2px 0 2px;
  border-radius: 100%;
  animation-name: animate-map-fancy-loader-dot;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

@for $i from 1 through 3 {
  .dot:nth-child(#{$i}n) {
    animation-delay: #{($i - 1) * 0.15}s;
  }
}

@keyframes animate-map-fancy-loader-dot {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  30%,
  50% {
    transform: scale(1);
  }
}
