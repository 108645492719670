.divider {
  padding: 0 1rem;
  display: flex;
  align-items: center;

  h2 {
    font-family: "Poppins";
    padding: 0 1rem;
    color: #989b9e;
    font-size: 0.75rem;
    font-weight: 400;
    font-style: normal;
    white-space: nowrap;
  }
}

.line {
  width: 100%;
  border: 1px solid #e0e0e0;
}
