.inputItem {
  position: relative;

  input {
    transition: 0.5s;
    width: 100%;
    border: 1px solid #d7d8db;
    background: #ffffff;
    border-radius: 3px;
    padding: 1.5rem 0 1rem 1.3125rem;
  }

  span {
    position: absolute;
    left: 0;
    pointer-events: none;
    transition: 0.5s;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #c1c5cc;
    padding: 1rem 0 1rem 1.3125rem;
  }

  input:focus ~ span,
  input:valid ~ span {
    font-size: 0.75rem;
    transform: translatey(-0.6rem);
  }

  input:focus {
    border: 1px solid #2898ff;
  }

  &_active {
    input {
      border: 1px solid red;
    }
  }
}

.showPassword {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  right: 0;
  bottom: 0;
  right: 1rem;
  margin-bottom: 0 !important;

  svg {
    path {
      fill: #d7d8db;
    }
  }

  &_active {
    svg {
      path {
        fill: #2898ff;
      }
    }
  }
}

.visibilityControl {
  cursor: pointer;
  margin-bottom: 0 !important;

  svg {
    display: block;
  }
}
